import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <AiOutlineMail />,
    url: 'mailto:sawasdee@deeboon.com',
    tooltip: 'อีเมล',
  },
  {
    icon: <AiOutlinePhone />,
    url: 'tel:0917656679',
    tooltip: 'โทรศัพท์',
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const { contentAbout } = useStaticQuery(
    graphql`
      query {
        contentAbout: allContentfulAboutPage {
          edges {
            node {
              heroTitle
              heroDescription {
                heroDescription
              }
              heroImage {
                fluid(maxWidth: 1770, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              content {
                content
              }
            }
          }
        }
      }
    `
  );

  const { heroTitle, heroDescription, heroImage, content } =
    contentAbout.edges[0].node;

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2 dangerouslySetInnerHTML={{ __html: heroTitle }} />
        <p
          dangerouslySetInnerHTML={{ __html: heroDescription.heroDescription }}
        />
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={heroImage.fluid} alt="about" />
      </AboutImage>

      <AboutDetails>
        <ReactMarkdown remarkPlugins={[gfm]} children={content.content} />
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
