import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/about';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  const { contentSeo } = useStaticQuery(
    graphql`
      query {
        contentSeo: allContentfulAboutPage {
          edges {
            node {
              seoTitle
              seoDescription
            }
          }
        }
      }
    `
  );

  const { seoTitle, seoDescription } = contentSeo.edges[0].node;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <About />
    </Layout>
  );
};

export default AboutPage;
